import React from 'react'
import logo from '../../assets/images/avatars/cerebrum logos final_Cerebrum Logo.png'
const Loader = () => {
    return (
        <div className='loader-container'>
            <img src={logo} alt="" />
            <div id="container-ball">
            <div id="ball-1" class="circle-ball"></div>
            <div id="ball-2" class="circle-ball"></div>
            <div id="ball-3" class="circle-ball"></div>
            <div id="ball-4" class="circle-ball"></div>
            <div id="ball-5" class="circle-ball"></div>
        </div>
        </div>
    )
}

export default Loader