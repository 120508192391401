import Cookies from 'universal-cookie'

export const saveJWTinCookie = cookie => {
  localStorage.setItem('jwt_token', JSON.stringify(cookie));

 // const cookies = new Cookies()
  //cookies.set('jwt_token', cookie, { path: '/', expires: new Date(cookie.expiration) })
}

export const getJWTfromCookie = () => {
  const cookies = new Cookies()
  let a = localStorage.getItem("jwt_token")
  if (a!=null || a != undefined) {
    return true
  }
 // return cookies.get('jwt_token')
 return false
}

export const getRolefromCookie = () => {
  const cookies = new Cookies()
  let a = localStorage.getItem("jwt_token")
  if (a!=null || a != undefined) {
    let data = JSON.parse(a)
    try{
      return data["Role"]
    } catch(e) {
      return ""
    }
  
  }
 // return cookies.get('jwt_token')
 return ""
}

export const removeCookie = () => {
  localStorage.clear()
  }
