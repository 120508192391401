import { Component, Suspense } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import './scss/style.scss'
//import Login from './views/pages/login/newLogin.jsx'
//import Register from './views/pages/Register/Register.jsx'
import { getJWTfromCookie } from './utils/handleCookie.js'
//import DefaultLayout from './layout/DefaultLayout'
import { QueryClient, QueryClientProvider } from 'react-query'
import './index.css'
import 'antd/dist/antd.less'
import { Toaster } from 'react-hot-toast'

import { lazy } from 'react'
import Loader from './components/shared/Loader'
const Login = lazy(() => import('./views/pages/login/newLogin.jsx'))
const VerificationOtp = lazy(() => import('./views/dashboard/VerificationsOtp'))
const Register = lazy(() => import('./views/pages/Register/Register.jsx'))
const DefaultLayout = lazy(() => import('./layout/DefaultLayout'))


// Pages
const ProtectedRoute = ({ component: Component, ...rest }) => <Route {...rest} render={(props) => (getJWTfromCookie() ? <Component {...props} /> : <Redirect to={{ pathname: '/login' }} />)} />
const PublicRoute = ({ component: Component, ...rest }) => <Route {...rest} render={(props) => (getJWTfromCookie() ? <Redirect to={{ pathname: '/home' }} /> : <Component {...props} />)} />
const queryClient = new QueryClient()


window.NavigationDetails = {}
window.DiagnosList = []
window.PatientData = []
window.ActiveSelection = true
class App extends Component {

  render() {
    return (
      <QueryClientProvider client={queryClient}>
        <Router>
          <Toaster />
          <Suspense fallback={<Loader />}>
            <Switch>
              <PublicRoute exact path="/" name="Login Page" component={() => <Login />} />
              <ProtectedRoute path="/home" component={() => <DefaultLayout />} />
              <PublicRoute exact path="/login" name="Login Page" component={() => <Login />} />
              <ProtectedRoute exact path="/verification" name="Otp Verification" component={() => <VerificationOtp />} />
              <ProtectedRoute exact path="/register" name="Register page" component={() => <Register />} />
              <ProtectedRoute path="/dashboard" component={() => <DefaultLayout />} />
              <ProtectedRoute path="/datawarehouse" component={() => <DefaultLayout />} />
              <ProtectedRoute path="/details" component={() => <DefaultLayout />} />
              <ProtectedRoute path="/questions" component={() => <DefaultLayout />} />
              <ProtectedRoute path="/childTable" component={() => <DefaultLayout />} />
            </Switch>
          </Suspense>

        </Router>
      </QueryClientProvider>
    )
  }
}

export default App
